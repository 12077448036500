export const TrendData = [
  {
    name: "Minions",
    shares: 100,
  },
  {
    name: "Avengers",
    shares: 200,
  },
  {
    name: "Frozen",
    shares: 300,
  },
  {
    name: "Toy Story",
    shares: 400,
  },
  {
    name: "Lion King",
    shares: 500,
  },
];
