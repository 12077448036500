import img1 from "../img/img1.png";
import img2 from "../img/img2.png";
import img3 from "../img/img3.png";
import img4 from "../img/img4.jpg";

export const Followers = [
  { name: "John Doe", username: "JohnDoe", img: img1 },
  { name: "Jane Doe", username: "JaneDoe", img: img2 },
  { name: "John Smith", username: "JohnSmith", img: img3 },
  { name: "Jane Smith", username: "JaneSmith", img: img4 },
];
